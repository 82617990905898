@import '@fullcalendar/core/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';
@import './app/theme/margins.scss';
@import './app/theme/paddings.scss';

.fc-event {
  border-radius: 0px!important;
}

.fc-day-grid-event .fc-content {
  padding: 3px;
}

.fc-button {
  background: transparent;
  border: none;
  color: #424242;
  padding-right: 16px;
}

.fc-button:after {
  border: none;
  outline: none;
}

.fc-button:hover, .fc-button:active {
  color: #5020dc;
  background: transparent;
  border: none;
}

.fc-button-primary:not(:disabled):active, 
.fc-button-primary:not(:disabled).fc-button-active {
  color: #5020dc;
  background: transparent;
  border: none;
}

.fc-button-primary:not(:disabled):active:after, 
.fc-button-primary:not(:disabled).fc-button-active:after {
  content: "";
  height: 2px;
  background-color: #5020dc;
  display: block;
  position: relative;
}

body {
  font-family: "Source Sans Pro", sans-serif !important;
}

h1 {
  color: #fff;
  font-size: 21px;
  font-weight: 400;
}

p {
  font-size: 16px;
  font-weight: 200;
  color: #fff;
  line-height: 24px;
}

button {
  font-family: "Source Sans Pro", sans-serif !important;
  border-radius: 4px !important;
}

.mat-flat-button {
  border-radius: 4px !important;
  font-size: 14px;
  padding: 6px 25px 6px 25px !important;
  line-height: 24px!important;
}

.txtBlack {
  color: #000;
}

.mat-form-field {
  display: block;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif !important;
}

.mat-form-field-label-wrapper {
  font-size: 15px;
}

input.mat-input-element {
  font-size: 16px;
}

form {
  margin: 12px 0;
}

.mat-checkbox-layout {
  white-space: normal !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 15px;
  font-weight: 300;
  margin: 12px 0;
}

.mat-menu-item {
  font-size: 13px;
  padding: 0px 24px !important;
  height: 40px!important;
  line-height: 20px!important;
  border-radius: 0px!important;
}

.btnSecondary {
  background: #dfd4ff;
  padding: 10px 32px;
  border: none;
  min-width: 90px;
  font-size: 14px;
  font-weight: 600;
  color: #541cc7;
}

.btnClear {
  background: transparent;
  padding: 10px 32px;
  border: none;
  min-width: 90px;
  font-size: 14px;
  font-weight: 600;
  color: #424242;
}

.tableHead {
  border-bottom: #e3e3e3 solid 1px;
  color: #424242;
  font-size: 14px;
}

.tableRow {
  border-bottom: #e3e3e3 solid 1px;
  color: #424242;
  font-size: 14px;
}

.tableRow:hover {
  background: #f8f6ff;
}

.tableRow h2 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}

.mat-grid-tile .mat-figure {
  justify-content: flex-start !important;
  align-items: center !important;
}

.clearfix {
  clear: both !important;
}

.mat-grid-tile-checkbox .mat-checkbox {
  margin: 0px 12px;
}

.tableHead .mat-grid-tile .mat-figure,
.tableRow .mat-grid-tile .mat-figure {
  justify-content: flex-start !important;
  align-items: center !important;
}

.tableAction.mat-grid-tile .mat-figure {
  justify-content: flex-end !important;
  align-items: center !important;
}

.mat-button-custom {
  padding: 0px !important;
  margin: 0px !important;
  min-width: 34px !important;
}

.mat-paginator {
  font-size: 14px;
  font-family: "Source Sans Pro";
  background: #fff;
  border-top: #eaeaea solid 1px;
}

.mat-select-value {
  font-size: 15px;
}

.appFormInput {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.appFormInput .appLabel {
  font-size: 14px;
  color: #949494;
  margin-bottom: 8px;
  font-weight: 400;
}

.mat-radio-button {
  font-size: 20px;
  font-family: "Source Sans Pro";
  color: #424242;
  margin-right: 16px;
  font-weight: 400;
  .mat-radio-label-content {
    font-weight: 400;
  }
}


.mat-button, button {
  outline: none!important;
}

.mat-button.mat-primary {
  background: #612aff;
  color: #fff;
  padding: 0px 30px;
  font-size: 15px;
  font-weight: 400;
}

.mat-button.mat-secondary {
  background: #dcdcdc;
  color: #424242;
  padding: 0px 30px;
  font-size: 15px;
  font-weight: 400;
}

.mat-button.mat-primary:disabled, .mat-button.mat-secondary:disabled {
  background: #dfdfdf;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-tab-label .mat-tab-label-content {
  font-family: "Source Sans Pro" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #000;
}

.mat-select-value,
.mat-option {
  font-size: 16px;
  font-weight: 600;
  font-family: "Source Sans Pro" !important;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-xs-8 {
  width: 60%;
  float: left;
}

.col-xs-4 {
  width: 33.33333333%;
  float: left;
}

.col-xs-8,
.col-xs-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}


table, table tr, table tr td {
  font-family: "Source Sans Pro", sans-serif !important;
}

.text-right {
  text-align: right!important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.modal-content {
  padding: 0 12px;
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: none!important; 
  border-radius: 0px!important;
  outline: 0;
  box-shadow: none!important;
}

.modal-header {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 15px 0px!important;
}

.modal-header h2 {
  margin: 4px 0px;
  font-size: 18px;
  font-weight: 600;
}
.modal-header small { 
  font-size: 14px; 
}

.modal-body {
  padding-top: 10px!important;
  padding-bottom: 18px!important;
  min-height: 400px;
}

.mat-dialog-actions {
  padding: 16px 0!important;
}

td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type {
  padding-left: 0px!important;
}

.app-form-wrapper {
  padding-top: 36px;
}

.medium-gray {
  border-bottom: #ddd solid 1px;
}

.canvas-container { 
  margin:0 auto;            
}

// Scrollbar
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #333333; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  opacity: 1
}

::-webkit-scrollbar-thumb:hover {
  background: #612aff; 
  border-radius: 10px;
  opacity: 0.5
}

.well {
  padding: 16px!important;
  background-color: #fff!important;
  box-shadow: none!important;
}
.activity-body {
  background-color: #f9f9f9;
}

.mat-option-text {
  font-size: 13px;
}

.white__color {
  color: #fff!important;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #fff!important;
}

.mat-dialog-title {
  color: #424242;
}

.linkContent {
  width: 100%;
  height: 100%;
}