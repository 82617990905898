.p0 {
    padding: 0px!important;
}
.pt0{
    padding-top: 0px!important;
}
.pt0{
    padding-top: 0px!important;
}
.pt5{
    padding-top: 5px!important;
}
.pt10{
    padding-top: 10px!important;
}
.pt15{
    padding-top: 15px!important;
}
.pt20{
    padding-top: 20px!important;
}
.pt25{
    padding-top: 25px!important;
}
.pt30{
    padding-top: 30px!important;
}
.pt35{
    padding-top: 35px!important;
}
.pt40{
    padding-top: 40px!important;
}

.pb0{
    padding-top: 0px!important;
}
.pb0{
    padding-bottom: 0px!important;
}
.pb5{
    padding-bottom: 5px!important;
}
.pb10{
    padding-bottom: 10px!important;
}
.pb15{
    padding-bottom: 15px!important;
}
.pb20{
    padding-bottom: 20px!important;
}
.pb25{
    padding-bottom: 25px!important;
}
.pb30{
    padding-bottom: 30px!important;
}
.pb35{
    padding-bottom: 35px!important;
}
.pb40{
    padding-bottom: 40px!important;
}
.pl0{
    padding-left: 0px!important;
}
.pl5{
    padding-left: 5px!important;
}
.pl10{
    padding-left: 10px!important;
}
.pl15{
    padding-left: 15px!important;
}
.pl20{
    padding-left: 20px!important;
}
.pl25{
    padding-left: 25px!important;
}
.pl30{
    padding-left: 30px!important;
}
.pl35{
    padding-left: 35px!important;
}
.pl40{
    padding-left: 40px!important;
}
.pr0{
    padding-right: 0px!important;
}
.pr5{
    padding-right: 5px!important;
}
.pr10{
    padding-right: 10px!important;
}
.pr15{
    padding-right: 15px!important;
}
.pr20{
    padding-right: 20px!important;
}
.pr25{
    padding-right: 25px!important;
}
.pr30{
    padding-right: 30px!important;
}
.pr35{
    padding-right: 35px!important;
}
.pr40{
    padding-right: 40px!important;
}