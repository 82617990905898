@import '@angular/material/theming';

@include mat-core(); 


$mat-custom-primary: (
    50: #ece5ff,
  100: #d0bfff,
  200: #b095ff,
  300: #906aff,
  400: #794aff,
  500: #612aff,
  600: #5925ff,
  700: #4f1fff,
  800: #4519ff,
  900: #330fff,
  A100: #ffffff,
  A200: #f8f7ff,
  A400: #cbc4ff,
  A700: #b5abff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$mat-custom-accent: (
    50: #ffffff,
    100: #fefefe,
    200: #fdfdfd,
    300: #fcfcfc,
    400: #fcfcfc,
    500: #fbfbfb,
    600: #fafafa,
    700: #fafafa,
    800: #f9f9f9,
    900: #f8f8f8,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$digital-signage-primary: mat-palette($mat-custom-primary);
$digital-signage-accent: mat-palette($mat-custom-accent);
$digital-signage-warn: mat-palette($mat-red);
$digital-signage-theme: mat-light-theme($digital-signage-primary, $digital-signage-accent, $digital-signage-warn);

@include angular-material-theme($digital-signage-theme);

html, body { height: 100%; }
body { margin: 0; font-family: "Source Sans Pro", sans-serif; }